import * as React from "react";
import {PageContentContainer} from "../views/styled/styled";
import {
    CenteredRowFlexBox,
    FlexCol,
} from "../views/simulation/create-simulation/styled";
import Box from "@mui/material/Box";
import {
    Alert,
    CircularProgress,
    MenuItem,
    Modal,
    Select,
    Tooltip,
} from "@mui/material";
import {ArrowBackIosNew} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import CodeMirror from "@uiw/react-codemirror";
import {useEffect, useState} from "react";
import {useAppState} from "../state/AppStateProvider";
import {python} from "@codemirror/lang-python";
import {ArtifactVersionItem} from "../views/strategy/ArtifactVersionItem";
import useSWR from "swr";
import {
    fetchArtifactFilesWithUrl,
    getArtifactDetails,
    toggleStrategyAccess,
    starStrategy,
    unstarStrategy,
    cloneArtifactStrat, deleteStrategy,
} from "../api/hasura";
import {
    LibraryArtifact,
    LibraryArtifactVersion,
    ArtifactStar,
} from "./StrategyLibraryWidget";
import {formatDateValue} from "../views/simulation/create-simulation/PoolDetails";
import Chip from "@mui/material/Chip";

import {
    Badge,
    Button,
    DropdownMenu,
    Flex,
    IconButton,
    Spinner,
} from "@radix-ui/themes";
import {
    ArrowLeftIcon,
    ChevronDownIcon,
    StarIcon,
    StarFilledIcon,
    ChevronLeftIcon,
    DotsHorizontalIcon,
    ExitIcon,
    PersonIcon,
    DotsVerticalIcon,
} from "@radix-ui/react-icons";
import {Select as RadixSelect, Card} from "@radix-ui/themes";
import {AlmanakModalContent} from "../modals/ScenarioCustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import {formatPubKey} from "../utils/commonUtils";
import TextField from "@mui/material/TextField";

export const StrategyLibraryDetailsWidget = () => {
    const navigate = useNavigate();
    let {artifactId} = useParams();

    const {theme, user, setSnackBar} = useAppState();

    // Fetching a single artifact instead of the entire list as it should be more performant
    // And would not revalidate the entire cache when starring
    const {data: artifact, mutate} = useSWR(
        artifactId ? `artifact-${artifactId}` : null,
        () => getArtifactDetails(artifactId as string)
    );

    console.log("StrategyLibraryDetailsWidget artifact", artifact);

    const [isStarring, setIsStarring] = useState(false);
    const isStarred = artifact?.artifact_stars?.some(
        (star: ArtifactStar) => star.user_id === user?.id
    );

    const handleStarToggle = async () => {
        setIsStarring(true);
        try {
            if (isStarred) {
                await unstarStrategy(artifactId as string);
                setSnackBar({
                    open: true,
                    message: "Successfully unstarred strategy",
                    severity: "success",
                });
            } else {
                await starStrategy(artifactId as string);
                setSnackBar({
                    open: true,
                    message: "Successfully starred strategy",
                    severity: "success",
                });
            }
            await mutate();
        } catch (e: any) {
            setSnackBar({
                open: true,
                message: `Error: ${e?.message}`,
                severity: "error",
            });
        } finally {
            setIsStarring(false);
        }
    };

    const onDeleteStrategy = async () => {
        setIsDeleteModalVisible(false);
        console.log("artifact", artifact);
        try {
            const res = await deleteStrategy(artifact?.id as string);
            console.log("res", res);
            if (res.message) {
                setSnackBar({
                    open: true,
                    message: "Successfully deleted strategy",
                    severity: "success",
                });
                navigate("/strategy-library");
            } else {
                setSnackBar({
                    open: true,
                    message: "Error deleting strategy. Make sure the strategy is not being run by any agent before deleting.",
                    severity: "error",
                });
            }
        } catch (e: any) {
            setSnackBar({
                open: true,
                message: `Error deleting strategy. Make sure the strategy is not being run by any agent before deleting. ${e?.message}`,
                severity: "error",
            });
        }
    }


    const isUserAuthor = artifact?.author === user?.id;
    const isPublic = artifact?.is_public;
    const [artifactVersion, setArtifactVersion] =
        useState<LibraryArtifactVersion>(artifact?.latest_version_artifact);
    const [code, setCode] = useState<any>(null);
    const [selectedFile, setSelectedFile] = useState(
        artifactVersion?.artifact_files?.[0]
    );

    useEffect(() => {
        console.log("StrategyLibraryDetailsWidget artifact changed!!");
        if (!artifactVersion && artifact?.latest_version_artifact) {
            setArtifactVersion(artifact.latest_version_artifact);

            // Set the initial selected file based on the updated `artifactVersion`
            setSelectedFile(
                artifact.latest_version_artifact.artifact_files?.[0] || null
            );
        }
    }, [artifact, artifactVersion]);

    console.log("StrategyLibraryDetailsWidget artifactVersion", artifactVersion);
    const [isChangeAccessModalOpen, setIsChangeAccessModalOpen] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isChangeAccessLoading, setIsChangeAccessLoading] = useState(false);

    console.log("StrategyLibraryDetailsWidget selectedFile", selectedFile);

    const artifactName = artifact?.name;
    const {data: artifactFilesWithUrl} = useSWR(
        artifactVersion ? `${artifactVersion?.id}-files` : null,
        () => fetchArtifactFilesWithUrl(artifactName, artifactVersion?.name)
    );

    console.log(
        "StrategyLibraryDetailsWidget selectedFile artifactFilesWithUrl",
        artifactFilesWithUrl
    );
    // const selectedFileUrl = artifactFilesWithUrl?.find()

    const selectedFileAuthenticatedUri = artifactFilesWithUrl?.find(
        (f: any) => f.id === selectedFile.id
    ).presigned_url;
    const {data: selectedFileContents, isLoading: fileLoading} = useSWR(
        selectedFileAuthenticatedUri,
        async () => {
            const res = await fetch(selectedFileAuthenticatedUri);
            return res.text();
        }
    );

    const onVersionChange = (version: string) => {
        const selectedVersion = artifact?.artifact_versions?.find(
            (s: LibraryArtifact) => s.id === version
        );
        setArtifactVersion(selectedVersion);

        setSelectedFile(selectedVersion?.artifact_files?.[0]);
    };

    const toggleAccessChange = async () => {
        setIsChangeAccessModalOpen(false);
        setIsChangeAccessLoading(true);

        try {
            const res = await toggleStrategyAccess(
                artifact?.id,
                !artifact?.is_public
            );
            console.log("res", res);
            setSnackBar({
                open: true,
                message: "Strategy is now " + (res.is_public ? "Public" : "Private"),
                severity: "success",
            });
        } catch (e: any) {
            console.error("error", e);
            setSnackBar({
                open: true,
                message: `Error changing access: ${e?.message}`,
                severity: "error",
            });
        } finally {
            await mutate();
            setIsChangeAccessLoading(false);
        }
    };

    const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
    const [cloneName, setCloneName] = useState("");
    const [isCloning, setIsCloning] = useState(false);

    useEffect(() => {
        if (isCloneModalOpen && artifact?.name) {
            setCloneName(`Clone of ${artifact.name}`);
        }
    }, [isCloneModalOpen, artifact?.name]);

    const handleClone = async () => {
        setIsCloning(true);
        try {
            const response = await cloneArtifactStrat(
                artifact?.id as string,
                cloneName
            );
            console.log("response", response);
            if (response.valid) {
                setSnackBar({
                    open: true,
                    message: "Successfully cloned strategy",
                    severity: "success",
                });
                setIsCloneModalOpen(false);
                navigate("/strategy-library");
            } else {
                setSnackBar({
                    open: true,
                    message: "Error cloning strategy",
                    severity: "error",
                });
            }
        } catch (e: any) {
            setSnackBar({
                open: true,
                message: `Error cloning strategy: ${e?.message}`,
                severity: "error",
            });
        } finally {
            setIsCloning(false);
        }
    };

    return (
        <PageContentContainer>
            <Box></Box>
            <Flex justify={"between"} align={"center"}>
                <Flex align={"center"} gap={"16px"}>
                    <Button variant="outline" onClick={() => navigate(-1)}>
                        <ArrowLeftIcon/>
                        Back
                    </Button>
                    <h2>{artifact?.name}</h2>
                </Flex>
                <Flex align={"center"} gap={"16px"}>
                    <Button
                        variant="soft"
                        loading={isStarring}
                        onClick={handleStarToggle}
                    >
                        {isStarred ? <StarFilledIcon/> : <StarIcon/>}
                        {isStarred ? "Starred" : "Star"}
                        <span style={{marginLeft: "4px"}}>
              {artifact?.stars_count || 0}
            </span>
                    </Button>

                    <DropdownMenu.Root>
                        <DropdownMenu.Trigger>
                            <Button variant={"soft"} loading={isChangeAccessLoading}>
                                <DotsVerticalIcon/>
                            </Button>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content style={{minWidth: "144px"}}>
                            <DropdownMenu.Item onClick={() => setIsCloneModalOpen(true)}>
                                Clone Strategy
                            </DropdownMenu.Item>
                            {/*Only show the make public option. once strategy is public there's no making it private*/}
                            {isUserAuthor && (
                                <>
                                   {!artifact?.is_public && <DropdownMenu.Item
                                        onClick={() => setIsChangeAccessModalOpen(true)}
                                    >
                                        Make {artifact?.is_public ? "Private" : "Public"}
                                    </DropdownMenu.Item>}

                                    <DropdownMenu.Item
                                        color={"red"}
                                        onClick={() => setIsDeleteModalVisible(true)}
                                    >
                                        Delete Strategy
                                    </DropdownMenu.Item>
                                </>
                            )}
                        </DropdownMenu.Content>
                    </DropdownMenu.Root>
                </Flex>
            </Flex>

            <Box sx={{display: "flex", gap: 4}}>
                <Box>
                    <div
                        style={{
                            padding: "16px 0 8px 0",
                            fontSize: "14px",
                            fontWeight: "bold",
                        }}
                    >
                        Creation Date
                    </div>
                    <span style={{fontSize: "14px"}}>
            {formatDateValue(artifact?.date_created)}
          </span>
                </Box>

                <Box>
                    <div
                        style={{
                            padding: "16px 0 8px 0",
                            fontSize: "14px",
                            fontWeight: "bold",
                        }}
                    >
                        Access
                    </div>
                    <Badge
                        color={artifact?.is_public ? "green" : "gray"}
                        radius={"full"}
                        size={"1"}
                    >
                        {artifact?.is_public ? "Public" : "Private"}
                    </Badge>
                </Box>
            </Box>

            <Box sx={{display: "flex", gap: 4}}>
                <Box>
                    <div
                        style={{
                            padding: "16px 0 8px 0",
                            fontSize: "14px",
                            fontWeight: "bold",
                        }}
                    >
                        Description
                    </div>
                    <span style={{fontSize: "14px"}}>
            {!!artifact?.description
                ? artifact.description
                : "No Description available"}
          </span>
                </Box>
            </Box>

            <Box>
                {artifact?.metadata?.tags && (
                    <Box>
                        <div
                            style={{
                                padding: "16px 0 8px 0",
                                fontSize: "14px",
                                fontWeight: "bold",
                            }}
                        >
                            Tags
                        </div>
                        <CenteredRowFlexBox sx={{gap: "8px"}}>
                            {artifactVersion.metadata?.tags.map((tag: string) => (
                                <Chip label={tag}/>
                            ))}
                        </CenteredRowFlexBox>
                    </Box>
                )}
            </Box>

            {/*{isUserAuthor && <FlexCol sx={{gap: "16px"}}>*/}
            {/*    <Box>*/}
            {/*        <Box sx={{my: 2}} style={{fontSize: "14px", fontWeight: "bold"}}>Author Controls</Box>*/}

            {/*        <Button loading={isChangeAccessLoading} onClick={() => setIsChangeAccessModalOpen(true)}*/}
            {/*                size={"1"}>Make {artifact?.is_public ? "Private" : "Public"}</Button>*/}
            {/*    </Box>*/}
            {/*</FlexCol>}*/}

            <FlexCol sx={{gap: "16px"}}>
                <Box>
                    <Box sx={{my: 2}} style={{fontSize: "14px", fontWeight: "bold"}}>
                        Strategy Versions
                    </Box>
                    <RadixSelect.Root
                        onValueChange={onVersionChange}
                        value={artifactVersion?.id}
                    >
                        <RadixSelect.Trigger/>
                        <RadixSelect.Content>
                            {artifact?.artifact_versions?.map(
                                (version: LibraryArtifactVersion) => (
                                    <RadixSelect.Item key={version.id} value={version.id}>
                                        {version.name} - {formatDateValue(version.date_created)}
                                    </RadixSelect.Item>
                                )
                            )}
                        </RadixSelect.Content>
                    </RadixSelect.Root>

                    {/*<Select value={artifactVersion?.id} onChange={e => onVersionChange(e.target.value)} size={"small"}>*/}
                    {/*    {artifact?.artifact_versions?.map((version: LibraryArtifactVersion) => <MenuItem*/}
                    {/*        value={version.id}>{version.name} - {formatDateValue(version.date_created)}</MenuItem>)}*/}
                    {/*</Select>*/}
                </Box>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <h2>Version Details</h2>
                </div>
                {artifactVersion && (
                    <ArtifactVersionItem
                        artifact={artifact}
                        key={artifact.id}
                        artifactVersion={artifactVersion}
                        isDetails
                    />
                )}

                {artifactVersion?.artifact_files?.length > 0 && (
                    <>
                        <h2>Strategy Files</h2>
                        <Box sx={{display: "flex", minHeight: "420px"}}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    maxWidth: "180px",
                                }}
                            >
                                {artifactVersion?.artifact_files?.map((file) => {
                                    const fileName = file?.uri?.split("/")?.pop();

                                    return (
                                        <Tooltip placement={"right"} title={fileName}>
                                            <Button
                                                onClick={() => setSelectedFile(file)}
                                                radius={"none"}
                                                style={{whiteSpace: "nowrap", overflow: "hidden"}}
                                                variant={
                                                    file.id === selectedFile?.id ? "solid" : "outline"
                                                }
                                                value={"python"}
                                            >
                                                {fileName}
                                            </Button>
                                        </Tooltip>
                                    );
                                })}
                            </Box>
                            {!selectedFileContents && fileLoading && (
                                <Box sx={{display: "flex", m: 4, gap: "16px"}}>
                                    <div>Loading file contents</div>
                                    <div>
                                        <Spinner/>
                                    </div>
                                </Box>
                            )}
                            {selectedFileContents && (
                                <Box style={{width: "100%", marginLeft: "8px"}}>
                                    <CodeMirror
                                        readOnly={true}
                                        maxWidth={"60vw"}
                                        value={selectedFileContents}
                                        theme={theme}
                                        extensions={[python()]}
                                    />
                                </Box>
                            )}

                            {!selectedFileContents && !fileLoading && (
                                <Box sx={{display: "flex", m: 4, gap: "16px"}}>
                                    <div>No file contents available</div>
                                </Box>
                            )}
                        </Box>
                    </>
                )}
            </FlexCol>

            {isChangeAccessModalOpen && (
                <Modal
                    sx={{display: "flex"}}
                    open={isChangeAccessModalOpen}
                    onClose={() => setIsChangeAccessModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <AlmanakModalContent>
                        <Box
                            sx={{
                                p: 3,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "bold",
                            }}
                        >
                            Make strategy {artifact?.is_public ? "Private" : "Public"}
                            <IconButton
                                variant="outline"
                                onClick={() => setIsChangeAccessModalOpen(false)}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Box>

                        <Alert severity={"info"} sx={{m: 1}}>
                            You are about to make this strategy{" "} {artifact?.is_public ? "Private" : "Public. This action cannot be undone"}.
                        </Alert>

                        <Box
                            sx={{display: "flex", gap: 1, p: 3, justifyContent: "flex-end"}}
                        >
                            <Button
                                style={{borderRadius: "4px", padding: "4px 12px"}}
                                variant="soft"
                                onClick={() => setIsChangeAccessModalOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{borderRadius: "4px", padding: "4px 12px"}}
                                color="green"
                                onClick={toggleAccessChange}
                            >
                                Make {artifact?.is_public ? "Private" : "Public"}{" "}
                            </Button>
                        </Box>
                    </AlmanakModalContent>
                </Modal>
            )}

            {isDeleteModalVisible && (
                <Modal
                    sx={{display: "flex"}}
                    open={isDeleteModalVisible}
                    onClose={() => setIsDeleteModalVisible(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <AlmanakModalContent>
                        <Box
                            sx={{
                                p: 3,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "bold",
                            }}
                        >
                            Are you sure you want to delete this strategy?
                            <IconButton
                                variant="outline"
                                onClick={() => setIsDeleteModalVisible(false)}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Box>

                        <Alert severity={"info"} sx={{m: 1}}>
                            You are about to delete this strategy! This action cannot be undone. You can only delete strategies which are not currently in use by any agents. All strategy versions will be removed as well.
                        </Alert>

                        <Box
                            sx={{display: "flex", gap: 1, p: 3, justifyContent: "flex-end"}}
                        >
                            <Button
                                style={{borderRadius: "4px", padding: "4px 12px"}}
                                variant="soft"
                                onClick={() => setIsDeleteModalVisible(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{borderRadius: "4px", padding: "4px 12px"}}
                                color="red"
                                onClick={onDeleteStrategy}
                            >
                                Delete Strategy
                            </Button>
                        </Box>
                    </AlmanakModalContent>
                </Modal>
            )}

            {isCloneModalOpen && (
                <Modal
                    sx={{display: "flex"}}
                    open={isCloneModalOpen}
                    onClose={() => setIsCloneModalOpen(false)}
                    aria-labelledby="clone-modal-title"
                    aria-describedby="clone-modal-description"
                >
                    <AlmanakModalContent>
                        <Box
                            sx={{
                                p: 3,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "bold",
                            }}
                        >
                            Clone Strategy
                            <IconButton
                                variant="outline"
                                onClick={() => setIsCloneModalOpen(false)}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Box>

                        <Box sx={{p: 3}}>
                            <TextField
                                fullWidth
                                label="New Strategy Name"
                                value={cloneName}
                                onChange={(e) => setCloneName(e.target.value)}
                                placeholder="Enter name for cloned strategy"
                            />
                        </Box>

                        <Box
                            sx={{display: "flex", gap: 1, p: 3, justifyContent: "flex-end"}}
                        >
                            <Button
                                style={{borderRadius: "4px", padding: "4px 12px"}}
                                variant="soft"
                                onClick={() => setIsCloneModalOpen(false)}
                                disabled={isCloning}
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{borderRadius: "4px", padding: "4px 12px"}}
                                color="green"
                                onClick={handleClone}
                                disabled={!cloneName.trim() || isCloning}
                            >
                                {isCloning ? <Spinner/> : null}
                                {isCloning ? "Cloning..." : "Clone"}
                            </Button>
                        </Box>
                    </AlmanakModalContent>
                </Modal>
            )}
        </PageContentContainer>
    );
};
