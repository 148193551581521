import {get, post} from "./httpWrapper";
import {UserData} from "../hooks/useAuth";
import {
    ESimulationStatus,
    MarketScenario,
    SimulationMetadata,
    TokenPair,
} from "../state/types";
import {Dayjs} from "dayjs";
import Big from "big.js";
import {ApiKey} from "../views/user-profile/UserProfile";
import {remapAssets} from "./mappers";
import {SupportedChain} from "../views/wallets/WalletsOverview";

const hasuraApiUrl = process.env.REACT_APP_HASURA_API_URL;
const verifyWeb3AuthUrl = process.env.REACT_APP_VERIFY_AUTH_URL;

type GraphQLData = {
    operationName: string;
    query: string;
    variables?: any;
};

export type CreditPlan = {
    created_at: string;
    credits: number;
    currency: string;
    description: string | null;
    id: string;
    is_active: boolean;
    name: string;
    price: number;
    stripe_price_id: string;
    updated_at: string;
};

const getUser = async (id: string) => {
    const userQuery = `
    query MyQuery {
        users(where: {id: {_eq: "${id}"}}) {
          id
          firebase_uid
          main_name_service
          email
          notification_email
          wallet_address
          is_first_time
          user_teams {
            id
          }
          organisations {
            id
          }      
        }
    }`;

    const res = await executeQuery({
        operationName: "MyQuery",
        query: userQuery,
    });
    return res.data.data.users[0];
};

const updateUser = async (
    id: string,
    values: { name: string; notification_email: string }
) => {
    const userUpdateQuery = `
    mutation UpdateUser {
        update_users_by_pk(pk_columns: {id: "${id}"}, _set: {name: "${values.name}", notification_email: "${values.notification_email}"}) {
            email
            name
        }
    }
`;
    const res = await executeQuery({
        operationName: "UpdateUser",
        query: userUpdateQuery,
    });

    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    }
    return res.data.data.update_users_by_pk;
};

const updateUserFirstTimeStatus = async (userId: string, isFirstTime: boolean) => {
    const query = `
    mutation UpdateFirstTimeStatus {
        update_users_by_pk(
            pk_columns: {id: "${userId}"}, 
            _set: {is_first_time: ${isFirstTime}}
        ) {
            id
            is_first_time
        }
    }`;

    const res = await executeQuery({
        operationName: "UpdateFirstTimeStatus",
        query,
    });

    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    }
    return res.data.data.update_users_by_pk;
};

const getUserTeam = async (userId: string) => {
    const userQuery = `
   query MyQuery {
        user_team(where: {user_id: {_eq: "${userId}"}}) {
          id
          organisation_id
          team_id
          user {
            id
            email
            notification_email
            main_name_service
          }
        }
}
`;

    const res = await executeQuery({
        operationName: "MyQuery",
        query: userQuery,
    });

    const isNotWhitelisted =
        res.data.errors?.[0]?.message ===
        "Authentication hook unauthorized this request";

    if (isNotWhitelisted) {
        throw new Error("User is not whitelisted");
    } else {
        return res.data?.data?.user_team?.[0];
    }
};

const toggleStrategyAccess = async (strategyId: string, isPublic: boolean) => {
    const query = `
    mutation ToggleStrategyAccess {
        update_artifacts_by_pk(pk_columns: {id: "${strategyId}"}, _set: {is_public: ${isPublic}}) {
    id
    is_public
  }
}`;

    const res = await executeQuery({
        operationName: "ToggleStrategyAccess",
        query,
    });

    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    }
    return res.data.data.update_artifacts_by_pk;
};

const getAccessToken = async (idToken: string, publicKey: string) => {
    const data = {
        idToken,
        publicKey,
    };
    return post(verifyWeb3AuthUrl!, data, true);
};

const executeQuery = async (data: GraphQLData) => {
    return post(hasuraApiUrl!, data);
};

const getSimulations = async () => {
    const simulationsQuery = `query GetSimulations {
        simulations(order_by: {created_at: desc}) {
            id
            organisation_id
            created_at
            status
            description
        }
    }`;
    return executeQuery({
        operationName: "GetSimulations",
        query: simulationsQuery,
    });
};

const getGroupSimulations = async () => {
    const groupSimulationsQuery = `query GetGroupSimulations {
          group_simulations(order_by: {created_at: desc}) {
            frontend_state
            created_at
            created_by
            started_at
            finished_at
            agent_configs
            price_configs
            metadata
            description
            status_reason
            id
            status
            version
          }
    }`;
    return executeQuery({
        operationName: "GetGroupSimulations",
        query: groupSimulationsQuery,
    });
};

const getSimulation = async (id: string) => {
    const simulationQuery = `
    query GetSimulation {
        simulations_by_pk(id: "${id}") {
          id
          created_at
          created_by
          description
          organisation_id
          status
          storage_url
          team_id
          metadata
  }
}`;
    const simulationRes = await executeQuery({
        operationName: "GetSimulation",
        query: simulationQuery,
    });
    return simulationRes.data.data.simulations_by_pk;
};

const optimizationModule = {
    type: "arakis",
    protocols: [
        {
            name: "Uniswap",
            parameters: [
                {
                    name: "P_Protocol_fee",
                    parametrization: "uniform",
                    inputDataType: "list[float,float]",
                    description: "Protocol parameter description..",
                    inputValues: [0.1, 0.25],
                },
                {
                    name: "P_fee_zero",
                    parametrization: "uniform",
                    inputDataType: "float",
                    description: "Protocol parameter description..",
                    inputValues: 0.5,
                },
            ],
            metrics: [
                {name: "Protocol_Revenue", description: "desc"},
                {
                    name: "TVK_and_Uncollected_fees",
                    description: "desc",
                },
                {name: "trading_volume", description: "desc"},
            ],
            optimizationFunctions: [{name: "Function 1", description: "desc"}],
        },
        // ...other protocols
    ],
};

const getGroupSimulation = async (id: string) => {
    const groupSimulationQuery = `
        query GetGroupSimulation {
            group_simulations_by_pk(id: "${id}") {
              agent_configs
              agents_alias
              created_at
              created_by
              gcs_url
              description
              id
              metadata
              frontend_state
              organisation_id
              price_configs
              status
              status_reason
              system_notes
              team_id
              updated_at
              started_at
              finished_at
              version
            }
        }`;
    const simulationRes = await executeQuery({
        operationName: "GetGroupSimulation",
        query: groupSimulationQuery,
    });
    return simulationRes.data.data.group_simulations_by_pk;
};

const fetchAggregatedMetricsFromUri = async (uri: string) => {
    const res = await get(uri, true);
    return res?.data;
};

const getAggregatedMetricsForGroupSimulation = async (id: string) => {
    const query = `
            mutation GenerateAggregatedMetricsUri {
                generateAggregatedMetricsURI(arg1: {group_simulation_id: "${id}"}) {
                  message
                  uri
                  valid
                }
            }`;
    const uriRes = await executeQuery({
        operationName: "GenerateAggregatedMetricsUri",
        query,
    });
    if (uriRes.data.errors) {
        throw new Error(uriRes.data.errors[0].message);
    }
    return uriRes.data.data.generateAggregatedMetricsURI.uri;
};

const startSimulation = async (simulationId: string) => {
    const query = `
        mutation StartSimulation {
            startSimulation(arg1: {simulation_reference_id: "${simulationId}"}) {
                run_ids
            }
        }
    `;
    return executeQuery({operationName: "StartSimulation", query});
};

const refreshENSName = async () => {
    const query = `
        mutation refreshENSName {
            refreshENSName {
        valid
        }
    }`;


    return executeQuery({operationName: "refreshENSName", query});
};

const startGroupSimulation = async (groupSimulationId: string) => {
    const query = `
        mutation StartGroupSimulation {
            startGroupSimulation(arg1: {group_simulation_id: "${groupSimulationId}", version: 2}) {
              message
              valid
            }
        }
    `;
    return executeQuery({operationName: "StartGroupSimulation", query});
};

const createSimulation = async (
    metadata: SimulationMetadata,
    user: UserData
) => {
    const createSimulationQuery = `mutation CreateSimulation($metadata: jsonb = {}) {
              insert_simulations_one(object: 
                {
                description: "Description",
                metadata: $metadata,
                storage_url: "https://stoprage.almanak.co/simulations",
                organisation_id: "${user.organisationId}",
                created_by: "${user.id}",
                status: "pending",
                team_id: "${user.teamId}"
                updated_at: "${new Date().toUTCString()}"
                }
              ) {
                  id
                  created_at
                  metadata
                  frontend_state
                }
            }`;
    return executeQuery({
        operationName: "CreateSimulation",
        query: createSimulationQuery,
        variables: {metadata: metadata},
    });
};

export type GroupSimulation = {
    id?: string;
    isLoading?: boolean;
    gcs_url?: string;
    status?: ESimulationStatus;
    description?: string;
    created_at?: string;
    updated_at?: string;
    metadata?: any;
    agent_configs: any[];
    agents_alias?: string;
    price_configs: string[];
    frontend_state: SimulationMetadata;
    aggregated_metrics?: any;
    status_reason?: string;
    system_notes?: string;
    simulationMetricsUri?: string;
    started_at?: Date;
    finished_at?: Date;
    version?: number;
};

const createGroupSimulation = async (
    payload: GroupSimulation,
    user: UserData
) => {
    const updatePayload = {
        ...payload,
        organisation_id: user.organisationId,
        created_by: user.id,
        team_id: user.teamId,
        created_at: new Date(),
    };
    const createGroupSimulationQuery = `
            mutation CreateGroupSimulation($payload: group_simulations_insert_input = {}) {
                insert_group_simulations_one(object: $payload) {
                  id
                  metadata
                  frontend_state
                  description
                  created_at
                  status
                }
            }`;
    return executeQuery({
        operationName: "CreateGroupSimulation",
        query: createGroupSimulationQuery,
        variables: {payload: updatePayload},
    });
};

const updateSimulation = async (id: string, metadata: SimulationMetadata) => {
    const createSimulationQuery = `mutation UpdateSimulation($metadata: jsonb = {}) {
                                        update_simulations_by_pk(pk_columns: {id: "${id}"}, _set: {metadata: $metadata}) {
                                          id
                                        }
                                   }`;

    return executeQuery({
        operationName: "UpdateSimulation",
        query: createSimulationQuery,
        variables: {metadata: metadata},
    });
};

// const dynamicVariablesInQuery = `${metadata ? "$metadata: jsonb = {}," : ""}${frontend_state ? "$frontend_state: jsonb = {}," : ""}${price_configs ? "$price_configs: jsonb = {}," : ""}${agent_configs ? "$agent_configs: jsonb = {}," : ""}${description ? "$description: String = ''" : ""}`;
// const dynamicSetInQuery = `{${metadata ? "metadata: $metadata," : ""}${frontend_state ? "frontend_state: $frontend_state," : ""}${price_configs ? "price_configs: $price_configs," : ""}${agent_configs ? "agent_configs: $agent_configs," : ""}${description ? ("description: $description") : ""}}`;

//TODO: TYPE
const updateGroupSimulation = async (
    id: string,
    props: {
        frontend_state?: SimulationMetadata | any;
        price_configs?: string[] | null;
        metadata?: any;
        agent_configs?: any[];
    }
) => {
    const {frontend_state, price_configs, metadata, agent_configs} = props;

    const dynamicVariablesInQuery = `${metadata ? "$metadata: jsonb = {}," : ""}${
        frontend_state ? "$frontend_state: jsonb = {}," : ""
    }${price_configs ? "$price_configs: jsonb = {}," : ""}${
        agent_configs ? "$agent_configs: jsonb = {}," : ""
    }`;
    const dynamicSetInQuery = `{${metadata ? "metadata: $metadata," : ""}${
        frontend_state ? "frontend_state: $frontend_state," : ""
    }${price_configs ? "price_configs: $price_configs," : ""}${
        agent_configs ? "agent_configs: $agent_configs," : ""
    }}`;

    const createSimulationQuery = `mutation UpdateSimulation(${dynamicVariablesInQuery}) {
                                        update_group_simulations_by_pk(pk_columns: {id: "${id}"} _set: ${dynamicSetInQuery}) {
                                          id
                                          metadata
                                          frontend_state
                                          price_configs
                                          agent_configs
                                        }
                                   }`;

    // const createSimulationQuery = `mutation UpdateSimulation($metadata: jsonb = {}, $frontend_state: jsonb = {}, $price_configs: jsonb = {}, $agent_configs: jsonb = {}) {
    //                                     update_group_simulations_by_pk(pk_columns: {id: "${id}"} _set: {frontend_state: $frontend_state, price_configs: $price_configs, metadata: $metadata, agent_configs: $agent_configs}) {
    //                                       id
    //                                       metadata
    //                                       frontend_state
    //                                       price_configs
    //                                       agent_configs
    //                                     }
    //                                }`

    let variables: any = {};

    if (metadata) {
        variables.metadata = metadata;
    }
    if (frontend_state) {
        variables.frontend_state = frontend_state;
    }
    if (price_configs) {
        variables.price_configs = price_configs;
    }
    if (agent_configs) {
        variables.agent_configs = agent_configs;
    }

    const res = await executeQuery({
        operationName: "UpdateSimulation",
        query: createSimulationQuery,
        variables: variables,
    });

    return res.data?.data?.update_group_simulations_by_pk;
};

type PriceTrajectoryOptions = {
    color: string;
};
type PriceTrajectory = {
    symbol1: string;
    symbol2: string;
    drift?: number[];
    volatility_scale: number;
    market_drift_mode?: string;
    max_percentage_change_historical_std_multiplier?: number;
    options: PriceTrajectoryOptions;
};
const getManyPriceData = (
    symbol1: string,
    symbol2: string,
    numberOfTrajectories: number,
    granularity: number,
    steps: number,
    priceTrajectories: PriceTrajectory[],
    start_block: number,
    end_block: number
) => {
    const volatilityMode = "ewma"; // ewma | cccgarch | constant

    const query = `query GetManyPriceDataByTrajectory($priceTrajectories: [PriceTrajectories]) {
                     getManyPriceData(arg1: {start_block: ${start_block}, end_block: ${end_block}, granularity: ${granularity}, numberOfTrajectories: ${numberOfTrajectories}, steps: ${steps}, volatilityMode: "${volatilityMode}", priceTrajectories: $priceTrajectories}) {
                       data {
                         gcs_uri
                         price_config_hashkey
                         options {
                           color
                         }
                       }
                     }
                   }`;

    return executeQuery({
        operationName: "GetManyPriceDataByTrajectory",
        query,
        variables: {priceTrajectories: priceTrajectories},
    });
};

const getManyPriceDataV2 = async (
    symbol1: string,
    symbol2: string,
    numberOfTrajectories: number,
    granularity: number,
    steps: number,
    start_block: number,
    end_block: number,
    color: string,
    volatilityScale: number,
    marketDriftMode: string,
    drift?: [number, number]
) => {
    const volatilityMode = "ewma"; // ewma | cccgarch | constant

    const query1 = `
    query GetManyPriceDataByTrajectory($drift: [Float]) {
        getManyPriceDataV2(arg1: {drift: $drift, market_drift_mode: "${marketDriftMode}", enable_black_swan_event: false, end_block: ${end_block}, granularity: ${granularity}, start_block: ${start_block}, steps: ${steps}, symbol1: "${symbol1}", symbol2: "${symbol2}", trajectories: ${numberOfTrajectories}, volatilityMode: "${volatilityMode}", volatility_scale: ${volatilityScale}, options: {color: "${color}"}}) {
          data {
            gcs_uri
            options {
              color
            }
            price_config_hashkey
            asset_options {
            options {
              color
            }
            symbol
            }  
          }
        }
    }`;

    const res = await executeQuery({
        operationName: "GetManyPriceDataByTrajectory",
        query: query1,
        variables: {drift: drift},
    });
    if (res.data?.errors?.[0].message) {
        throw new Error(res.data?.errors?.[0].message);
    }
    if (res.data?.data?.getManyPriceDataV2?.data) {
        return res.data?.data?.getManyPriceDataV2?.data;
    } else {
        throw new Error("Something went wrong. Please contact support.");
    }
};

const getManyPriceDataV3 = async (
    assetPairs: TokenPair[] | null,
    numberOfTrajectories: number,
    granularity: number,
    steps: number,
    start_block: number,
    end_block: number,
    scenario: MarketScenario
) => {
    if (!assetPairs) {
        throw new Error("No asset pairs selected");
    }
    const volatilityMode = "cccgarch"; // ewma | cccgarch | constant

    const config = {
        config: {},
        assets: assetPairs.map((pair) => {
            return {
                symbol: [pair.token0_name, pair.token1_name],
                config: {
                    drift: scenario.drift, //takes priority over the global drift
                    volatility_scale: scenario.volatilityMultiplier,
                    market_drift_mode: scenario.marketDriftMode,
                    enable_black_swan_event: false,
                },
                options: {
                    //take anything and return to frontend
                    color: scenario.color,
                },
            };
        }),
    };

    const query = `mutation GetManyPriceDataByV3($drift: [Float], $assets: [assetsTypeV3], $config: assetsTypeV3Config = {} ) {getManyPriceDataV3Async(arg1: {drift: $drift, end_block: ${end_block}, granularity: ${granularity}, start_block: ${start_block}, steps: ${steps}, trajectories: ${numberOfTrajectories}, volatilityMode: \"${volatilityMode}\", assets: $assets, config: $config})}`;

    const res = await executeQuery({
        operationName: "GetManyPriceDataByV3",
        query: query,
        variables: config,
    });
    if (res.data?.errors?.[0].message) {
        throw new Error(res.data?.errors?.[0].message);
    }
    if (res.data?.data?.getManyPriceDataV3Async) {
        return res.data?.data?.getManyPriceDataV3Async;
    } else {
        throw new Error("Something went wrong. Please contact support.");
    }
};

const getManyPriceDataV3Results = async (id: string) => {
    const query = `query getManyPriceDataV3Async {
        getManyPriceDataV3Async(id: "${id}") {
          created_at
          errors
          id
          output {
            data {
              gcs_uri
              options {
                color
              }
              price_config_hashkey
              asset_options {
                options {
                  color
                }
                symbol
              }
            }
          }
        }
    }`;

    const res = await executeQuery({
        operationName: "getManyPriceDataV3Async",
        query: query,
    });

    if (res.data?.errors?.[0].message) {
        throw new Error(res.data?.errors?.[0].message);
    }
    if (res.data?.data?.getManyPriceDataV3Async?.output?.data) {
        return res.data?.data?.getManyPriceDataV3Async.output.data;
    } else {
        if (res.data?.data?.getManyPriceDataV3Async?.errors) {
            return {
                inProgress: true,
                error: res.data?.data?.getManyPriceDataV3Async?.errors?.error,
            };
        }
        return {inProgress: true};
        // throw new Error("Something went wrong. Please contact support.")
    }
};

const getAvailablePools = () => {
    const query = `query GetTokenPairPoolData {
                        getTokenPairPoolData {
                          data {
                            token1_name
                            token1_address
                            token0_name
                            token0_address
                            symbol
                            pools {
                              fee
                              pool
                              token0_address
                              token0_name
                              token0_symbol
                              token1_address
                              token1_name
                              token1_symbol
                              number_of_minters
                              number_of_traders
                            }
                          }
                        }
                    }`;

    return executeQuery({
        operationName: "GetTokenPairPoolData",
        query,
    });
};
const getPriceSimulationData = (
    symbol1: string,
    symbol2: string,
    granularity: number,
    marketDrift: number,
    steps: number
) => {
    const volatilityMode = "cccgarch"; // ewma | cccgarch

    const priceSimulationQuery = `query GetPriceData {
                                      getPriceData(arg1: {granularity: ${granularity}, marketDrift: ${marketDrift}, steps: ${steps}, symbol1: "ETH", symbol2: "USDT", volatilityMode: "${volatilityMode}"}) {
                                          prices {
                                            historical
                                            simulated
                                            pair
                                          }
                                      }
                                  }`;

    return executeQuery({
        operationName: "GetPriceData",
        query: priceSimulationQuery,
    });
};

// Function to convert the pair string to an array
const parsePair = (pairString: string) => {
    return JSON.parse(pairString);
};
// export const convertData = (inputData: any, options: any) => {
//     //console.log("convert data inputData", inputData);
//     const outputData: any = {};
//     const pairString = Object.keys(inputData.prices.simulated)[0];
//     const pairArray = parsePair(pairString);
//
//     for (const mainKey in inputData) {
//         if (inputData.hasOwnProperty(mainKey)) {
//             outputData[mainKey] = {};
//             outputData[mainKey]['pair'] = pairArray;
//
//             for (const subKey in inputData[mainKey]) {
//                 if (inputData[mainKey].hasOwnProperty(subKey)) {
//                     outputData[mainKey][subKey] = inputData[mainKey][subKey][pairString] || [];
//                 }
//             }
//         }
//     }
//
//     //console.log("convert data outputData", outputData);
//
//
//     return {...outputData, ...options};
// }

export const convertData = (inputData: any, options: any) => {
    const outputData: any = {};
    const pairsSet = new Set();

    // Collect all unique pairs
    for (const mainKey in inputData) {
        if (inputData.hasOwnProperty(mainKey)) {
            for (const subKey in inputData[mainKey]) {
                if (inputData[mainKey].hasOwnProperty(subKey)) {
                    Object.keys(inputData[mainKey][subKey]).forEach((pairString) => {
                        pairsSet.add(pairString);
                    });
                }
            }
        }
    }

    const pairsArray = Array.from(pairsSet).map((pairString) =>
        JSON.parse(pairString as string)
    );

    for (const mainKey in inputData) {
        if (inputData.hasOwnProperty(mainKey)) {
            outputData[mainKey] = {};
            outputData[mainKey]["pairs"] = pairsArray;

            for (const subKey in inputData[mainKey]) {
                if (inputData[mainKey].hasOwnProperty(subKey)) {
                    outputData[mainKey][subKey] = {};

                    Array.from(pairsArray).forEach((pairString) => {
                        // Correctly mapping the pairString with input data
                        const dataForPair = inputData[mainKey][subKey][pairString];
                        if (dataForPair) {
                            outputData[mainKey][subKey][pairString] = dataForPair;
                        }
                    });
                }
            }
        }
    }

    return {...outputData, ...options};
};

export const convertDataForMultiplePairs = (inputData: any, options: any) => {
    const outputData: any = {};

    const pairs = Object.keys(inputData.prices.simulated);
    pairs.forEach((pairString) => {
        const pairArray = parsePair(pairString);
        outputData[pairString] = {}; // Create a nested object for each pair

        for (const mainKey in inputData) {
            if (inputData.hasOwnProperty(mainKey)) {
                outputData[pairString][mainKey] = {};
                outputData[pairString][mainKey]["pair"] = pairArray;

                for (const subKey in inputData[mainKey]) {
                    if (
                        inputData[mainKey].hasOwnProperty(subKey) &&
                        inputData[mainKey][subKey][pairString]
                    ) {
                        outputData[pairString][mainKey][subKey] =
                            inputData[mainKey][subKey][pairString];
                    } else {
                        outputData[pairString][mainKey][subKey] = [];
                    }
                }
            }
        }
    });

    return {...outputData, ...options};
};

const fetchPriceTrajectoryResults = async (url: string, options: any) => {
    return await get(url, true);
    // const res = await get(url, true)
    // return convertData(res?.data, options);
};

const getAgentsLibrary = () => {
    const agentsLibraryQuery = `
      query GetAgentCatalog {
        agent_catalog {
          author
          available
          agents_config
          class
          created_at
          total_usd_equivalent
          description
          id
          version
        }
    }`;
    return executeQuery({
        operationName: "GetAgentCatalog",
        query: agentsLibraryQuery,
    });
};

const getAgentById = (id: string) => {
    const query = `query GetAgentById {
                     agent_catalog_by_pk(id: "${id}") {
                       agents_config
                       author
                       class
                       created_at
                       description
                       id
                       total_usd_equivalent
                     }
                   }`;
    return executeQuery({operationName: "GetAgentById", query});
};

const getAvailableTokens = () => {
    //TODO: replace when token endpoint is available
    return [
        {
            token: "USDT",
            address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
            isNative: false,
            decimalPlaces: 6,
            base: Big(10),
            exponent: Big(6),
        },
        {
            token: "WETH",
            address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
            isNative: false,
            decimalPlaces: 18,
            base: Big(10),
            exponent: Big(18),
        },
        {
            token: "WBTC",
            address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
            isNative: false,
            decimalPlaces: 8,
            base: Big(10),
            exponent: Big(6),
        },
        {
            token: "ETH",
            address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
            isNative: true,
            nativeLabel: "Uniswap",
            decimalPlaces: 18,
            base: Big(10),
            exponent: Big(18),
        },
        {
            token: "BNT",
            address: "0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C",
            isNative: false,
            decimalPlaces: 18,
            base: Big(10),
            exponent: Big(18),
        },
        {
            token: "LINK",
            address: "0x514910771af9ca656af840dff83e8264ecf986ca",
            isNative: false,
            decimalPlaces: 18,
            base: Big(10),
            exponent: Big(18),
        },
    ];
};

const fetchMintersAndTraders = async (address: string) => {
    const query = `
        query FetchMintersAndTraders {
            fetchNumberOfTradersPerUniswapV3Pool(argData: {pool_address: "${address}"}) {
              number_of_traders
            }
            fetchNumberOfLpProvidersPerUniswapV3Pool(argData: {pool_address: "${address}"}) {
              number_of_minters
            }
        }`;
    return executeQuery({operationName: "FetchMintersAndTraders", query});
};

const generateWallets = async (numberOfWallets: number) => {
    const query = `
        mutation GenerateWallets {
            generateManyWalletAddress(arg1: {count: ${numberOfWallets}}) {
                wallet_address_array
            }
        }`;
    return executeQuery({operationName: "GenerateWallets", query});
};

const getAvailableTokenPairs = async () => {
    const query = `
            query GetCommonAssetPairs {
                common_us_asset_pairs {
                  Instrument
                  Timeframe
                  earliest_date
                  end_date
                  token1
                  token2
                }
    }`;
    const res = await executeQuery({
        operationName: "GetCommonAssetPairs",
        query,
    });

    return remapAssets(res.data.data.common_us_asset_pairs);
};

const getBlockHeightByTime = (date: Dayjs) => {
    const query = `
        query GetBlockHeightByTime {
            getBlockHeightByTime(arg1: {timestamp: "${date}"}) {
              block_height
            }
        }`;

    return executeQuery({operationName: "GetBlockHeightByTime", query});
};

//Generate token to open docs:

const getDocsAccessToken = async (): Promise<{ customToken: string }> => {
    const query = `
        mutation GenerateCustomSigninToken {
        generateCustomSigninToken {
            customToken
        }
    }`;

    const res = await executeQuery({
        operationName: "GenerateCustomSigninToken",
        query,
    });
    return res.data.data.generateCustomSigninToken;
};

const getUserCreditBalance = async (): Promise<any> => {
    const query = `
    query GetCreditBalance {
        getCreditBalance {
          credit_balance
        }
      }`;

    const res = await executeQuery({operationName: "GetCreditBalance", query});
    return res?.data?.data?.getCreditBalance?.credit_balance ?? 0;
};

const getCreditPackages = async (): Promise<CreditPlan[]> => {
    const query = `
        query GetCreditPackages {
                credit_packages {
                  created_at
                  credits
                  currency
                  description
                  id
                  is_active
                  name
                  price
                  stripe_price_id
                  updated_at
                }
        }`;

    const res = await executeQuery({operationName: "GetCreditPackages", query});

    return res?.data?.data?.credit_packages ?? [];
};

const purchaseCreditPackage = async (creditPackageId: string): Promise<any> => {
    const isProduction = process.env.REACT_APP_ENVIRONMENT === "production";
    const errorUrl = isProduction
        ? "https://app.almanak.co/buy-credits/error"
        : "https://stage.almanak.co/buy-credits/error";
    const successUrl = isProduction
        ? "https://app.almanak.co/buy-credits/success"
        : "https://stage.almanak.co/buy-credits/success";

    const query = `
        mutation CreatePaymentLink {
          createPaymentLink(arg1: {
              cancel_url: "${errorUrl}", 
              credit_package_id: "${creditPackageId}",
              success_url: "${successUrl}"
          }) {
            message
            url
            valid
          }
        }`;

    const res = await executeQuery({operationName: "CreatePaymentLink", query});

    console.log("res", res);
    const url = res?.data?.data?.createPaymentLink?.url;
    if (!url) {
        throw new Error("Error creating payment link");
    }
    return url;
};

const getCostAndDurationEstimate = async (
    group_simulation_id: string
): Promise<any> => {
    const query = `
    query GetCostAndDurationEstimate {
        getCostAndDurationEstimate(arg1: {group_simulation_id: "${group_simulation_id}"}) {
          estimated_cost
          estimated_duration
          message
          valid
        }
      }`;

    return executeQuery({operationName: "GetCostAndDurationEstimate", query});
};

const getApiKey = async (): Promise<ApiKey> => {
    const query = `
        query GetApiKey {
            user_api_key(where: {active: {_eq: true}}) {
              api_key
              created_at
              jwt
              active
            }
        }`;
    const res = await executeQuery({operationName: "GetApiKey", query});
    return res?.data?.data?.user_api_key?.[0] ?? null;
};

const resetApiKey = async (): Promise<ApiKey> => {
    const query = `
        mutation ResetApiKey {
             resetApiKey {
               active
               api_key
               created_at
               jwt
             }
        }`;

    const res = await executeQuery({operationName: "ResetApiKey", query});
    return res?.data?.data?.resetApiKey ?? null;
};

const getSupportedChains = async (): Promise<SupportedChain[]> => {
    const query = `
       query GetSupportedChains {
         supported_chain(where: {is_active: {_eq: true}}) {
          block_explorer_url
          chain_id
          chain_id_hex
          chain_name_space
          currency
          is_active
          is_layer2
          is_testnet
          minimum_comfirmation_block_number
          name
          rpc_url
        }
} `;

    const res = await executeQuery({
        operationName: "GetSupportedChains",
        query,
    });
    return res?.data?.data?.supported_chain ?? null;
};

const getArtifactLibraryItems = async () => {
    const query = `
      query ArtifactLibraryItems {
        artifacts(order_by: {stars_count: desc}) {
            id
            name
            metadata
            date_created
            description
            is_public
            author
            author_user {
              email
            }
            latest_version_artifact {
              id
              date_created
              description
              is_public
              metadata
              name
              uri
              artifact_files {
                id
                uri
                authenticated_url
              }
              author_user {
                email
                
              }
            }
            artifact_versions {
                id
                date_created
                description
                is_public
                metadata
                name
                uri
                artifact_files {
                    id
                    uri
                    authenticated_url
                }
                author_user {
                    email
                    
                }
            }
            stars_count
            artifact_stars {
                id
                user_id
                created_at
            }
        }
    }
    
`;

    const res = await executeQuery({
        operationName: "ArtifactLibraryItems",
        query,
    });
    return res?.data?.data?.artifacts;
};


const getPublicArtifactLibraryItems = async (userId: string) => {

    const query = `
    query GetPublicArtifacts {
  get_public_artifacts_with_stars(args: {current_user_id: "${userId}"}, order_by: {stars_count: desc}) {
          author_wallet_address
          date_created
          description
          id
          name
          stars_count
          is_star_by_user
          type
          }
    }`

    const res = await executeQuery({
        operationName: "GetPublicArtifacts",
        query,
    });

    console.log("getPublicArtifactLibraryItems res", res);

    return res?.data?.data?.get_public_artifacts_with_stars;
};

// Unused atm, but as the artifact library grows, it might be more performant to fetch the starred artifacts directly
// Currently, we fetch all artifacts and cache them via useSWR in the strategyLibraryWidget, and further filter to get the results we want
const getStarredArtifactLibraryItems = async () => {
    const query = `
      query StarredArtifacts {
        artifact_stars(order_by: {artifact: {stars_count: desc}}) {
          artifact {
            id
            name
            metadata
            date_created
            description
            is_public
            author
            author_user {
              email
              name
            }
            latest_version_artifact {
              id
              date_created
              description
              is_public
              metadata
              name
              uri
              artifact_files {
                id
                uri
                authenticated_url
              }
              author_user {
                email
                name
              }
            }
            artifact_versions {
              id
              date_created
              description
              is_public
              metadata
              name
              artifact_files {
                id
                uri
                authenticated_url
              }
              author_user {
                email
                name
              }
            }
            stars_count
          }
        }
      }
    `;

    const res = await executeQuery({operationName: "StarredArtifacts", query});
    return res?.data?.data?.artifact_stars;
};

const getSafeWalletHoldings = async (address: string) => {
    const query = `
        query GetSafeWalletHoldings {
          getTokenPrice(arg1: {
              action: "current", 
              safe_address: "${address}", 
              type: "wallet",
            }) {
              safe_address
              user_address
              total_usd_balance
              total_usd_balance_yesterday
              profit_losses_1day
              profit_losses_display
              token {
                token_id
                chain
                symbol
                total_token_amount
                price_usd
                current_usd_price_per_token
            }
          }
        }`;

    const res = await executeQuery({
        operationName: "GetSafeWalletHoldings",
        query,
    });
    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    }
    return res.data.data.getTokenPrice;
};

const createSmartWallet = async (
    chainId: string,
    name: string,
    type: string
) => {
    // createSmartWallet(arg1: {chain_id: "${chainId}", name: "${name}", type: "${type}") {

    const mutationQuery = `
        mutation CreateSmartWallet {
          createSmartWallet(arg1: {chain_id: "${chainId}", name: "${name}", type: "${type}"}) {
            message
            smartWalletDetails {
              id
              chain_id
              eoa_address
              is_active
              is_in_used
              is_layer2
              is_testnet
              name
              type
              user_id
              chain {
                block_explorer_url
                chain_id
                chain_id_hex
                chain_name_space
                currency
                is_active
                is_layer2
                is_testnet
                minimum_comfirmation_block_number
                name
                rpc_url
              }
            }
            valid
          }
        }    
    `;

    const res = await executeQuery({
        operationName: "CreateSmartWallet",
        query: mutationQuery,
    });

    console.log("createSmartWallet res", res);

    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    }
    return res.data.data.createSmartWallet;
};

const refreshJwtToken = async () => {
    const mutationQuery = `
          mutation RefreshJwtToken {
             refreshJwtToken {
                 expirationTime
                 message
                 token
                 valid
             }
         }
    `;

    //response
    // {
    //     "data": {
    //     "refreshJwtToken": {
    //         "expirationTime": "Thu, 29 Aug 2024 06:43:53 GMT",
    //             "message": "Token refreshed successfully",
    //             "token": "...",
    //             "valid": true
    //     }
    // }
    // }

    const res = await executeQuery({
        operationName: "RefreshJwtToken",
        query: mutationQuery,
    });

    console.log("RefreshJwtToken res", res);

    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    }
    return res.data.data.refreshJwtToken.token;
};

const getSafeTransactionHistory = async (
    address?: string,
    chainId?: string
) => {
    if (!address || !chainId) {
        return null;
    }

    const query = `
        query GetSafeTransactionHistory {
          getSafeWalletTxHistory(arg1: {address: "${address}", chain_id: "${chainId}"}) {
            message
            transactions {
              chain
              chain_id
              timestamp
              tx_hash
              tx_name
            }
            valid
          }
        }
    `;

    const res = await executeQuery({
        operationName: "GetSafeTransactionHistory",
        query,
    });

    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    }
    return res.data.data.getSafeWalletTxHistory.transactions;
};
const getSmartWalletById = async (id?: string) => {
    if (!id) {
        return null;
    }
    const query = `
            query GetSmartWalletById {
              smart_contract_wallet(where: {id: {_eq: "${id}"}}) {
                id
                status
                address
                chain_id
                created_at
                creation_payload
                is_active
                is_in_used
                is_layer2
                is_testnet
                name
                tx_hash
                type
                updated_at
                user_id
                external_own_accounts {
                     address
                     gcs_url
                     smart_contract_wallet_id
                     user_id
                }
                transactions {
                    chain
                    chain_id
                    is_scam
                    project_id
                    send_to_addr
                    send_token
                    send_token_amt
                    safe_address
                    category_id
                    receive_from_addr
                    receive_token
                    receive_token_amt
                    transaction_hash
                    user_address
                    tx_name
                    tx_value
                }
              }
            }
    `;

    const res = await executeQuery({
        operationName: "GetSmartWalletById",
        query,
    });
    return res?.data?.data?.smart_contract_wallet?.[0];
};

const requestFreeCredits = async () => {
    const query = `
        mutation RequestFreeCredits {
          requestFreeCredits {
            message
            valid
          }
        }`;

    const res = await executeQuery({
        operationName: "RequestFreeCredits",
        query: query,
    });

    return res.data.data.requestFreeCredits;
};

const updateSmartWallet = async (
    walletId: string,
    txHash: string,
    address: string
) => {
    const query = `mutation UpdateSmartWalletWithTXHash {
        updateSmartWallet(arg1: {address: "${address}", smart_wallet_id: "${walletId}", txhash: "${txHash}"})
        {
          message
          valid
        }
    }`;

    const res = await executeQuery({
        operationName: "UpdateSmartWalletWithTXHash",
        query: query,
    });

    console.log("updateSmartWallet res", res);
    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    }
    return res.data.data.updateSmartWallet;
};
const getSmartWallets = async (
    chainId?: string,
    status?: string
): Promise<any> => {
    let whereClause = "";

    const isChainDefined = chainId && chainId !== "ALL";

    if (isChainDefined) {
        whereClause = `chain_id: {_eq: "${chainId}"}`;
    }

    if (status) {
        whereClause = `status: {_eq: "${status}"}`;
    }

    if (isChainDefined && status) {
        whereClause = `chain_id: {_eq: "${chainId}", status: {_eq: "${status}"}}`;
    }

    const query = `
        query GetSmartWallets {
          smart_contract_wallet(where: {${whereClause}}) {
            user_id
            updated_at
            type
            tx_hash
            name
            is_testnet
            is_layer2
            is_in_used
            is_active
            id
            created_at
            status
            creation_payload
            chain_id
            address
            external_own_accounts {
              address
              gcs_url
              smart_contract_wallet_id
              user_id
            }
          }
        }
    `;

    const res = await executeQuery({operationName: "GetSmartWallets", query});
    return res?.data?.data?.smart_contract_wallet;
};

const getLiveAgents = async () => {
    const query = `
        query GetLiveAgents {
          live_agent {
            artifact_id_version_id
            await_status_update
            await_status_update_at
            config
            created_at
            environment_metadata
            id
            name
            status
            strategy_version
            updated_at
            user_id
          }
        }
    `;
    const res = await executeQuery({operationName: "GetLiveAgents", query});

    return res?.data?.data?.live_agent;
};

const getLiveAgentById = async (agentId: string) => {
    const query = `
      query GetLiveAgentById {
        live_agent_by_pk(id: "${agentId}") {
        id
        artifact_id_version_id
        await_status_update
        await_status_update_at
        config
        created_at
        environment_metadata
        name
        status
        strategy_version
        updated_at
        user_id
        artifact_id_version {
          artifact {
            name
            id
          }
          id
          name
        }
        }
      }
`;

    const res = await executeQuery({operationName: "GetLiveAgentById", query});

    return res?.data?.data?.live_agent_by_pk;
};

const getEthBalanceForWallet = async (address: string, chainId: string) => {
    const query = `
        query GetEthBalance {
          getEthBalance(arg1: {address: "${address}", 
            chain_id: "${chainId}"}) {
                message
                valid
                balance
            }
        }
    `;

    const res = await executeQuery({operationName: "GetEthBalance", query});
    return res?.data?.data?.getEthBalance?.balance;
};

const createLiveAgent = async (
    agentName: string,
    artifact_id_version_id: string,
    config: any,
    strategyVersion: string
) => {
    const query = `
        mutation CreateAgent($config: jsonb!) {
            insert_live_agent_one(object: {artifact_id_version_id: "${artifact_id_version_id}", strategy_version: "${strategyVersion}", config: $config, name: "${agentName}"}) {
              artifact_id_version_id
              config
              created_at
              environment_metadata
              id
              name
              status
              updated_at
              user_id
            }
        }`;

    const res = await executeQuery({
        operationName: "CreateAgent",
        query,
        variables: {config: config},
    });
    return res?.data?.data?.insert_live_agent_one;
};

const deployLiveAgent = async (agentId: string) => {
    const query = `
       mutation DeployAgent {
        deployLiveAgent(arg1: {agentId: "${agentId}"}) {
            message
            valid
        }
    }`;

    const res = await executeQuery({
        operationName: "DeployAgent",
        query,
    });
    return res?.data?.data?.deployLiveAgent;
};
const terminateLiveAgent = async (agentId: string) => {
    const query = `
          mutation TerminateAgent {
            terminateAgent(arg1: {agentId: "${agentId}"}) {
              message
              valid
            }
          }
      `;

    const res = await executeQuery({operationName: "TerminateAgent", query});
    return res?.data?.data?.terminateAgent;
};

const redeployLiveAgent = async (agentId: string) => {
    const query = `
          mutation RedeployAgent {
            redeployAgent(arg1: {agentId: "${agentId}"}) {
              message
              valid
            }
          }
      `;
    const res = await executeQuery({operationName: "RedeployAgent", query});
    return res?.data?.data?.redeployAgent;
};

const pauseLiveAgent = async (agentId: string) => {
    const query = `
          mutation PauseAgent {
            pauseAgent(arg1: {agentId: "${agentId}"}) {
              message
              valid
            }
          }
      `;
    const res = await executeQuery({operationName: "PauseAgent", query});
    return res?.data?.data?.pauseAgent;
};
const fetchArtifactFilesWithUrl = async (
    artifactName: string,
    version: string
) => {
    const query = `
        mutation FetchArtifactsWithUrls($artifactName: String!, $version: String!) {
          generateArtifactDownloadUrl(artifactName: $artifactName, version: $version) {
            files {
              id
              relative_path
              presigned_url
            }
          }
        }
    `;

    const res = await executeQuery({
        operationName: "FetchArtifactsWithUrls",
        query,
        variables: {artifactName: artifactName, version: version},
    });
    console.log("fetchArtifatWithUrl res", res);
    return res?.data?.data?.generateArtifactDownloadUrl?.files;
};

const fetchAgentLogs = async (agentId: string, pageToken?: string) => {
    const query = `
        query FetchAgentLogs {
          fetchAgentLogs(arg1: {agentId: "${agentId}", pageSize: 30, pageToken: "${
        pageToken ?? ""
    }"}) {
            logs {
              message
              timestamp
            }
            nextPageToken
          }
        }
    `;
    const res = await executeQuery({
        operationName: "FetchAgentLogs",
        query,
    });

    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    }
    return res?.data?.data?.fetchAgentLogs;
};

const getAgentTransactions = async (agentId: string) => {
    // const query = `
    //   query MyQuery {
    //     fetchAgentTransactions(arg1: {agentId: "${agentId}"}) {
    //       message
    //       transactions {
    //         chain
    //         chain_id
    //         safe_address
    //         transaction_hash
    //         tx_name
    //         user_address
    //       }
    //       valid
    //     }
    // }`

    const query = `
    query FetchAgentTransactions {
        fetchAgentTransactions(arg1: {agentId: "${agentId}", offset: 0, page: 1, sort: ""}) {
            chainId
            eoaAddress
            transactions {
                blockHash
                blockNumber
                contractAddress
                confirmations
                cumulativeGasUsed
                from
                functionName
                gas
                gasPrice
                gasUsed
                hash
                input
                isError
                methodId
                nonce
                timeStamp
                to
                transactionIndex
                txreceipt_status
                value
            }
            message
            offset
            page
            safeAddress
            valid
        }
    }
`;

    // In case agent is not deployed, we can use the mock data below
    // const mockAgentTransactions = {
    //     data: {
    //         fetchAgentTransactions: {
    //             chainId: "42161",
    //             eoaAddress: "0x5454d18bf7700270aabacc130533df3c20946fe9",
    //             transactions: [
    //             {
    //                 blockHash: "0x5d448f45611768c27125f3b45bb01b61fb9d721575a1ab6127b6de64bdb6ef0f",
    //                 blockNumber: 295362136,
    //                 contractAddress: "",
    //                 confirmations: 248692,
    //                 cumulativeGasUsed: 657496,
    //                 from: "0x9f8214399d06a21569f1068fd7b71fcae2fde068",
    //                 functionName: "execTransactionWithRole(address to,uint256 value,bytes data,uint8 operation,bytes32 roleKey,bool shouldRevert)",
    //                 gas: 241115,
    //                 gasPrice: 10000000,
    //                 gasUsed: 194872,
    //                 hash: "0x5d448f45611768c27125f3b45bb01b61fb9d721575a1ab6127b6de64bdb6ef0f",
    //                 input: "0x...",
    //                 isError: "0",
    //                 methodId: "0x468721a7",
    //                 nonce: 12,
    //                 timeStamp: "1705363200",
    //                 to: "0x69ca232d89ffb41ed51be9cd58372d1993500baa",
    //                 transactionIndex: 3,
    //                 txreceipt_status: "1",
    //                 value: "0"
    //             },
    //             {
    //                 blockHash: "0x6e64f159d337e494e1acaf458aecf85fe5869db5c7bbe12cc886e62c99a3094d",
    //                 blockNumber: 295362143,
    //                 contractAddress: "",
    //                 confirmations: 248685,
    //                 cumulativeGasUsed: 3116850,
    //                 from: "0x9f8214399d06a21569f1068fd7b71fcae2fde068",
    //                 functionName: "execTransactionWithRole(address to,uint256 value,bytes data,uint8 operation,bytes32 roleKey,bool shouldRevert)",
    //                 gas: 243996,
    //                 gasPrice: 10000000,
    //                 gasUsed: 199688,
    //                 hash: "0x6e64f159d337e494e1acaf458aecf85fe5869db5c7bbe12cc886e62c99a3094d",
    //                 input: "0x...",
    //                 isError: "0",
    //                 methodId: "0x468721a7",
    //                 nonce: 13,
    //                 timeStamp: "1705363500",
    //                 to: "0x69ca232d89ffb41ed51be9cd58372d1993500baa",
    //                 transactionIndex: 4,
    //                 txreceipt_status: "1",
    //                 value: "0"
    //             },
    //             {
    //                 blockHash: "0x96484b485ec83dc8d5f8b4ad0988b986756347162eeaefb0ddc52675f9463dd1",
    //                 blockNumber: 295362150,
    //                 contractAddress: "",
    //                 confirmations: 248678,
    //                 cumulativeGasUsed: 1624099,
    //                 from: "0x9f8214399d06a21569f1068fd7b71fcae2fde068",
    //                 functionName: "execTransactionWithRole(address to,uint256 value,bytes data,uint8 operation,bytes32 roleKey,bool shouldRevert)",
    //                 gas: 389266,
    //                 gasPrice: 10000000,
    //                 gasUsed: 312779,
    //                 hash: "0x96484b485ec83dc8d5f8b4ad0988b986756347162eeaefb0ddc52675f9463dd1",
    //                 input: "0x...",
    //                 isError: "0",
    //                 methodId: "0x468721a7",
    //                 nonce: 14,
    //                 timeStamp: "1705363800",
    //                 to: "0x69ca232d89ffb41ed51be9cd58372d1993500baa",
    //                 transactionIndex: 5,
    //                 txreceipt_status: "1",
    //                 value: "0"
    //             }
    //             ],
    //             message: "agent transactions fetched successfully",
    //             offset: 0,
    //             page: 1,
    //             safeAddress: "0x69ca232d89ffb41ed51be9cd58372d1993500baa",
    //             valid: true
    //         }
    //     }
    // };

    const res = await executeQuery({
        operationName: "FetchAgentTransactions",
        query,
    });

    // return mockAgentTransactions.data.fetchAgentTransactions

    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    } else {
        return res.data.data.fetchAgentTransactions;
    }
};

const getTopUpPaymentSmartContract = async (chainId: string) => {
    const query = `
       query GetTopUpPaymentSmartContract {
         topup_payment_contract {
           abi
           address
           chain_id
           fee_collector_address
         }
       }
    `;

    const res = await executeQuery({
        operationName: "GetTopUpPaymentSmartContract",
        query,
    });

    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    } else {
        return res.data.data.topup_payment_contract;
    }
};

const starStrategy = async (artifactId: string) => {
    const query = `
    mutation AddStar {
        addStarToStrategy(arg1: {artifactId: "${artifactId}"}) {
            success
            message
        }
    }`;

    const res = await executeQuery({operationName: "AddStar", query});
    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    }
    return res.data.data.addStarToStrategy;
};

const unstarStrategy = async (artifactId: string) => {
    const query = `
    mutation RemoveStar {
        removeStarFromStrategy(arg1: {artifactId: "${artifactId}"}) {
            success
            message
        }
    }`;

    const res = await executeQuery({operationName: "RemoveStar", query});
    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    }
    return res.data.data.removeStarFromStrategy;
};

const deleteStrategy = async (strategyId: string) => {
    const query = `
        mutation DeleteStrategy {
          deleteStrategy(artifactId: {artifactId: "${strategyId}"}) {
            message
        }
    }`;

    const res = await executeQuery({operationName: "DeleteStrategy", query});
    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    }
    return res.data.data.deleteStrategy;
};

const cloneArtifactStrat = async (strategyId: string, name: string, isPublic = false) => {
    const query = `
    mutation CloneStrat {
      cloneStrategy(arg1: {strategyId: "${strategyId}", newStrategyName: "${name}", isPublic: ${isPublic}}) {
        forkedStrategy
        valid
        message
      }
    }
  `;

    const res = await executeQuery({operationName: "CloneStrat", query});
    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    }
    return res.data.data.cloneStrategy;
};

// Gets the details of a single strategy, instead of an entire list
const getArtifactDetails = async (artifactId: string) => {
    const query = `
      query ArtifactDetails($artifactId: uuid!) {
        artifacts_by_pk(id: $artifactId) {
            id
            name
            metadata
            date_created
            description
            is_public
            author
            author_user {
              email
  
            }
            latest_version_artifact {
              id
              date_created
              description
              is_public
              metadata
              name
              uri
              artifact_files {
                id
                uri
                authenticated_url
              }
              author_user {
                email
     
              }
            }
            artifact_versions {
                id
                date_created
                description
                is_public
                metadata
                name
                artifact_files {
                    id
                    uri
                    authenticated_url
                }
                author_user {
                    email
  
                }
            }
            stars_count
            artifact_stars {
                id
                user_id
                created_at
            }
        }
    }`;

    const variables = {
        artifactId,
    };

    const res = await executeQuery({
        operationName: "ArtifactDetails",
        query,
        variables,
    });
    return res?.data?.data?.artifacts_by_pk;
};

const getAllWalletBalances = async () => {
    const query = `
       query GetAllWalletBalances {
        getAllWalletBalances {
          message
          total_usd_value
          valid
          wallet_count
          balances {
            address
            usd_value
          }
        }
      }
    `;

    const res = await executeQuery({
        operationName: "GetAllWalletBalances",
        query,
    });

    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    } else {
        return res.data.data.getAllWalletBalances;
    }
};

const getWalletPositions = async (address: string, chainId: string) => {
    const query = `
      query GetWalletPositions($address: String!, $chainId: String!) {
        getWalletPositions(arg1: {
          address: $address,
          chain_id: $chainId
        }) {
          valid
          message
          total_usd_value
          tokens {
            id
            chain
            name
            symbol
            display_symbol
            optimized_symbol
            decimals
            logo_url
            is_verified
            is_core
            price
            time_at
            amount
            raw_amount
          }
          protocols {
            id
            chain
            name
            site_url
            logo_url
            has_supported_portfolio
            portfolio_item_list {
              stats {
                asset_usd_value
                debt_usd_value
                net_usd_value
              }
              update_at
              name
              detail_types
              detail {
                supply_token_list {
                  id
                  chain
                  name
                  symbol
                  display_symbol
                  optimized_symbol
                  decimals
                  logo_url
                  is_verified
                  is_core
                  price
                  time_at
                  amount
                  raw_amount
                }
                reward_token_list {
                  id
                  chain
                  name
                  symbol
                  display_symbol
                  optimized_symbol
                  decimals
                  logo_url
                  is_verified
                  is_core
                  price
                  time_at
                  amount
                  raw_amount
                }
                borrow_token_list {
                  id
                  chain
                  name
                  symbol
                  display_symbol
                  optimized_symbol
                  decimals
                  logo_url
                  is_verified
                  is_core
                  price
                  time_at
                  amount
                  raw_amount
                }
              }
              proxy_detail {
                project {
                  id
                  name
                  site_url
                  logo_url
                }
                proxy_contract_id
              }
              pool {
                controller
                id
                chain
                project_id
              }
              position_index
            }
          }
        }
      }
    `;

    const res = await executeQuery({
        operationName: "GetWalletPositions",
        query,
        variables: {
            address,
            chainId
        }
    });

    if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
    } else {
        return res.data.data.getWalletPositions;
    }
};

export {
    getAccessToken,
    executeQuery,
    createSimulation,
    createGroupSimulation,
    getSimulation,
    getGroupSimulation,
    getSimulations,
    getGroupSimulations,
    startSimulation,
    startGroupSimulation,
    updateSimulation,
    updateGroupSimulation,
    getPriceSimulationData,
    getUser,
    getUserTeam,
    getAgentsLibrary,
    fetchPriceTrajectoryResults,
    getManyPriceData,
    getManyPriceDataV2,
    getManyPriceDataV3,
    getManyPriceDataV3Results,
    getAvailablePools,
    getAvailableTokens,
    fetchMintersAndTraders,
    getAgentById,
    generateWallets,
    getAvailableTokenPairs,
    getBlockHeightByTime,
    getAggregatedMetricsForGroupSimulation,
    fetchAggregatedMetricsFromUri,
    getDocsAccessToken,
    updateUser,
    getUserCreditBalance,
    getCostAndDurationEstimate,
    getApiKey,
    resetApiKey,
    getArtifactLibraryItems,
    getStarredArtifactLibraryItems,
    getPublicArtifactLibraryItems,
    getCreditPackages,
    purchaseCreditPackage,
    getSupportedChains,
    createSmartWallet,
    getSmartWalletById,
    getSmartWallets,
    updateSmartWallet,
    refreshJwtToken,
    requestFreeCredits,
    getSafeWalletHoldings,
    getLiveAgents,
    getLiveAgentById,
    createLiveAgent,
    fetchArtifactFilesWithUrl,
    deployLiveAgent,
    getEthBalanceForWallet,
    fetchAgentLogs,
    getAgentTransactions,
    getTopUpPaymentSmartContract,
    toggleStrategyAccess,
    getSafeTransactionHistory,
    terminateLiveAgent,
    redeployLiveAgent,
    pauseLiveAgent,
    starStrategy,
    unstarStrategy,
    getArtifactDetails,
    getAllWalletBalances,
    cloneArtifactStrat,
    deleteStrategy,
    getWalletPositions,
    refreshENSName,
    updateUserFirstTimeStatus
};
